import { render, staticRenderFns } from "./OldSkillRedirect.vue?vue&type=template&id=e8caeffa&scoped=true&lang=pug&"
import script from "./OldSkillRedirect.vue?vue&type=script&lang=ts&"
export * from "./OldSkillRedirect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8caeffa",
  null
  
)

export default component.exports